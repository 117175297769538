.container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--Gray-50, #F4F5F6);
}

.titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .title {
        color: var(--Dark-indigo, #0B1F35);
        font-size: 20px;
        font-weight: 600;
        line-height: 120%; /* 24px */
    }
    .idData {
        color: #546272;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px; /* 125% */
    }
}

.dataRow {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    .dataRowItem {
        display: flex;
        align-items: flex-end;
        gap: 4px;
        flex: 1 0 0;

        .dataRowItemKey {
            color: var(--Gray-600, #475467);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
        .dataRowItemFiller {
            flex-grow: 1;
            border-bottom: 1px dotted #BBC3CF;
        }
        .dataRowItemValue {
            color: var(--Gray-900-Brand, #0B1F35);
            font-size: 16px;
            font-weight: 400;
            line-height: 20px; /* 125% */
        }
    }
}

