.container {
    margin-top: 24px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--Gray-50, #F4F5F6);
}
.title {
    color: var(--Dark-indigo, #0B1F35);
    font-size: 20px;
    font-weight: 600;
    line-height: 120%; /* 24px */
}
