
.goBack {
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: #546272;
    align-items: center;
}
