.root {
  .filterButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filterContent {
    padding: 1rem 0;
    opacity: 0;
    height: 0;
    transition: var(--transition-timing);

    &.open {
      height: auto;
      opacity: 1;
    }
  }

  .tableHeadRow {
    background: #F4F5F6;
    font-size: 12px;
  }
}
